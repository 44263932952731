import axios from 'axios';



const chatGPTAPI = 'https://api.openai.com/v1/chat/completions';
const apiKey = "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";

export async function sendMessage(userMessage) {
  try {
    const response = await axios.post(chatGPTAPI, {
      model: 'gpt-4',
      messages: [{ role: 'user', content: userMessage + "Ask me any clarifying questions you may need to. When I type continue, resume where you left off."}],
      temperature: 0.7,
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiKey}`,
      },
    });
    const assistantMessage = response.data.choices[0].message.content;
    return assistantMessage;
  } catch (error) {
    console.error('Error sending message:', error);
    return null;
  }
}

export default { sendMessage };


