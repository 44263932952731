import React from 'react';

const ChatMessage = ({ message, user }) => {
  const isUser = message.user.id === "chatgpt";

  return (
    <div
      style={{
        textAlign: isUser ? 'left' : 'right' ,
        padding: '5px',
        marginBottom: '10px',
      }}
    >
      <div
        style={{
          display: 'inline-block',
          borderRadius: '5px',
          padding: '10px',
          backgroundColor: isUser ?  '#90CAF9' : '#1976D2', // Change the background color here
          color: isUser ? 'black' : 'white',
        }}
      >
        {message.text}
      </div>
    </div>
  );
};

export default ChatMessage;
