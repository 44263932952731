// import React, { useState, useEffect } from 'react';
// import { auth, signOut, db } from '../firebase';
// import { collection, doc, addDoc } from 'firebase/firestore';
// import ChatMessage from './ChatMessage';
// import SignIn from './SignIn';
// import { sendMessage } from '../utils/chatGPT';

import React, { useState, useEffect } from 'react';
import { auth, signOut, db } from '../firebase';
import { collection, doc, addDoc, query, orderBy, onSnapshot } from 'firebase/firestore';
import ChatMessage from './ChatMessage';
import SignIn from './SignIn';
import { sendMessage } from '../utils/chatGPT';

function ChatInterface() {
    const [user, setUser] = useState(null);
    const [messages, setMessages] = useState([]);
  
    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        setUser(user);
      });
      return () => unsubscribe();
    }, []);
  
    useEffect(() => {
      if (user) {
        const userMessagesRef = collection(db, `messages-${user.uid}`);
        const q = query(userMessagesRef, orderBy('createdAt'));
        const unsubscribe = onSnapshot(q, (snapshot) => {
          setMessages(snapshot.docs.map((doc) => doc.data()));
        });
  
        return () => unsubscribe();
      }
    }, [user]);
  

  const handleSendMessage = async (message) => {
    setMessages((prevMessages) => [...prevMessages, message]);

    const userMessagesRef = collection(db, `messages-${user.uid}`);
    await addDoc(userMessagesRef, message);

    const response = await sendMessage(message.text);
    if (response) {
      const assistantMessage = {
        id: Math.random().toString(36).substr(2, 10),
        text: response,
        createdAt: new Date(),
        user: {
          id: 'chatgpt',
          name: 'ChatGPT',
          avatar: 'https://placeimg.com/140/140/any',
        },
      };
      setMessages((prevMessages) => [...prevMessages, assistantMessage]);

      await addDoc(userMessagesRef, assistantMessage);
    }
  };

  if (!user) {
    return <SignIn />;
  }

  return (
    <div>
      <header>
        <button onClick={() => signOut(auth)}>Sign Out</button>
      </header>
      <main>
        <div className="messages">
          {messages.map((message) => (
            <ChatMessage key={message.id} message={message} />
          ))}
        </div>
      </main>
      <section>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            const input = event.target.elements.text;
            handleSendMessage({
              id: Math.random().toString(36).substr(2, 10),
              text: input.value,
              createdAt: new Date(),
              user: {
                id: user.uid,
                name: user.displayName,
                avatar: user.photoURL,
              },
            });
            input.value = '';
          }}
        >
          <input
            type="text"
            name="text"
            placeholder="Type your message here..."
          />
          <button type="submit">Send</button>
        </form>
      </section>
    </div>
  );
}

export default ChatInterface;
