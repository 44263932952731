// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { signInWithPopup, signOut as firebaseSignOut } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyC6DrEd8QVS95fe8ZaxpUrAx5FfL0HtoD0",
  authDomain: "billimize.firebaseapp.com",
  projectId: "billimize",
  storageBucket: "billimize.appspot.com",
  messagingSenderId: "961902369341",
  appId: "1:961902369341:web:e7bd5d975132bdfad9a854",
  measurementId: "G-P50W9GWNQQ",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);

const signInWithGoogle = () => {
  signInWithPopup(auth, provider)
    .then((result) => {
      console.log(result);
    })
    .catch((error) => {
      console.error(error);
    });
};

const signOut = () => {
  firebaseSignOut(auth)
    .then(() => {
      console.log('User signed out');
    })
    .catch((error) => {
      console.error(error);
    });
};

export { auth, provider, db, signInWithGoogle, signOut };
