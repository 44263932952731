// import React from 'react';
// import GoogleButton from 'react-google-button';
// import { signInWithGoogle } from '../firebase';

// const SignIn = () => {
//   const handleSignIn = () => {
//     signInWithGoogle();
//   };

//   return (
//     <div className="sign-in">
//       <h1>Welcome to Billimize!</h1>
//       <p>Please sign in with your Google account to continue.</p>
//       <GoogleButton onClick={handleSignIn} />
//     </div>
//   );
// };

// export default SignIn;


import React from 'react';
import GoogleButton from 'react-google-button';
import { signInWithGoogle } from '../firebase';

const SignIn = () => {
  const handleSignIn = () => {
    signInWithGoogle();
  };

  return (
    <div className="sign-in">
      <h1>Welcome to Billimize!</h1>
      <p>Please sign in with your Google account to continue.</p>
      <GoogleButton onClick={handleSignIn} />
    </div>
  );
};

export default SignIn;
