import React, { useState, useEffect } from 'react';
import { auth } from './firebase';
import SignIn from './components/SignIn';
import ChatInterface from './components/ChatInterface';
import './App.css';

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
      } else {
        setUser(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="App">
      {user ? <ChatInterface /> : <SignIn />}
    </div>
  );
}

export default App;
